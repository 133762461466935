<template>
  <component
    :is="componentType"
    :to="to"
    class="font-bold text-base border py-2 inline-flex flex-row items-center space-x-2 shadow-md"
    :class="[
      // custom color and background
      color !== 'default' && disabled === false
        ? `text-brand-${color}-100`
        : '',
      bg !== 'default' && disabled === false
        ? `bg-brand-${bg}-300 border-brand-${bg}-400 hover:bg-brand-${bg}-500 focus:bg-brand-${bg}-500`
        : '',
      textColorClass,
      bgClasses,
      // icon styling
      hasDefaultSlot && icon.side === 'default'
        ? 'px-4 justify-center'
        : 'justify-between',
      icon.side === 'left' ? 'pl-3 pr-4' : '',
      icon.side === 'right' ? 'pl-4 pr-3' : '',
      // disabled styling
      disabled
        ? 'text-brand-grey-600 bg-gray-100 border-brand-grey-200 shadow-none cursor-not-allowed'
        : 'hover:shadow-lg transition-all duration-200 cursor-pointer'
    ]"
    @click="$emit('click')"
  >
    <span class="flex flex-row items-center space-x-2">
      <icon
        v-if="icon.side === 'left'"
        class="text-2xl"
        :name="icon.name"
        :class="[
          hasDefaultSlot ? '' : 'my-px',
          icon.colour ? `text-brand-${icon.colour}` : ''
        ]"
      ></icon>
      <span v-if="hasDefaultSlot" class="inline-block whitespace-no-wrap mt-0.5"
        ><slot></slot
      ></span>
    </span>
    <icon
      v-if="icon.side === 'right'"
      class="text-2xl"
      :name="icon.name"
    ></icon>
  </component>
</template>

<script>
import { resolveComponent } from 'vue'
export default {
  name: 'TafeButton',
  props: {
    component: {
      type: String,
      default: 'a'
    },
    to: {
      type: [Object, String],
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'primary'
    },
    color: {
      type: String,
      default: 'default'
    },
    bg: {
      type: String,
      default: 'default'
    },
    icon: {
      type: Object,
      default() {
        return { name: 'IconEmpty', side: 'default', colour: undefined }
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  data() {
    return {
      defaultTypes: {
        primary: {
          color: 'text-white',
          bg: 'bg-brand-blue-500 border-brand-blue-400 hover:bg-brand-blue-700 focus:bg-brand-blue-700'
        },
        secondary: {
          color: 'text-black',
          bg: 'bg-gray-200 border-brand-grey-200 hover:bg-brand-grey-200 focus:bg-brand-grey-200'
        },
        secondaryLight: {
          color: 'text-black',
          bg: 'bg-gray-100 border-gray-200 hover:bg-brand-grey-200 focus:bg-brand-grey-200'
        },
        secondaryDark: {
          color: 'text-white',
          bg: 'bg-brand-grey-600 border-brand-grey-600 hover:bg-brand-grey-700 focus:bg-brand-grey-700'
        },
        internationalGrey: {
          color: 'text-int-grey-01',
          bg: 'bg-int-grey-03 border-int-grey-02 hover:bg-int-grey-025 focus:bg-int-grey-025'
        }
      }
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    hasType() {
      return (
        Object.keys(this.defaultTypes).includes(this.type) &&
        this.disabled === false
      )
    },
    textColorClass() {
      return this.hasType && this.color === 'default'
        ? this.defaultTypes[this.type].color
        : ''
    },
    bgClasses() {
      return this.hasType && this.bg === 'default'
        ? this.defaultTypes[this.type].bg
        : ''
    },
    componentType() {
      let componentType
      // If the component prop exists use it
      if (this.component) {
        // dynamically resolved NuxtLink component
        componentType =
          this.component === 'nuxt-link'
            ? resolveComponent('NuxtLink')
            : this.component
        // Else check for url and set anchor
      } else {
        componentType = this.$attrs?.href ? 'a' : 'button'
      }
      return componentType
    }
  }
}
</script>
